import React, { useState } from 'react'
import Dropdown from '../../../components/Dropdown'
import {
    KR_ADMIN_ANALYSIS_SETTLEMENTTOTAL,
    KR_ADMIN_ANALYSIS_TITLE_SECTION,
    KR_USER_TITLE_DEPOSIT,
    KR_USER_TITLE_WITHDRAW
} from '../../../translations/kor'
import FilterCalendar from '../../../components/FilterCalendar'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import { filterAnalysisList } from '../../../reduxs/actions/analysis.action'
import { handlegetTime } from '../../../utils/getTime'
import { getLocalStorage } from '../../../utils/localStorage'

const filterData = [
    {
        id: 1,
        name: '오늘'
    },
    {
        id: 2,
        name: '어제'
    },
    {
        id: 3,
        name: '7일 전'
    },
    {
        id: 4,
        name: '이번 달'
    },
    {
        id: 5,
        name: '전달'
    },
]

export default function AnalysisListData({ filter, dataAnalysis, analysisTotal }) {
    const dispatch = useDispatch()

    const [time, setTime] = useState()
    const [agentFilter, setAgentFilter] = useState()
    const [gamesiteFilter, setGamesiteFilter] = useState()

    const { userRole } = useSelector(state => state.auth)
    const role = getLocalStorage('role')

    const filterByAgent = (agent) => {
        const getTime = handlegetTime(time)

        const dataFilter = {
            page: 1,
            data: {
                agent_id: agent.agent_id,
                gamesite_id: gamesiteFilter || '',
                ...getTime
            }
        }

        setAgentFilter(agent.agent_id)
        dispatch(filterAnalysisList(dataFilter))
    }

    const filterByGamesite = (gamesite) => {
        const getTime = handlegetTime(time)

        const dataFilter = {
            filter: 'gamesite',
            page: 1,
            data: {
                gamesite_id: gamesite.gamesite_id,
                agent_id: agentFilter || '',
                ...getTime
            }
        }

        setGamesiteFilter(gamesite.gamesite_id)
        dispatch(filterAnalysisList(dataFilter))
    }

    const filterByDate = (date) => {
        const typedateFilter = date.id
        const getTime = handlegetTime(typedateFilter)

        const dataFilter = {
            page: 1,
            data: {
                ...getTime,
                gamesite_id: gamesiteFilter || '',
                agent_id: agentFilter || '',
            }
        }

        setTime(date.id)
        dispatch(filterAnalysisList(dataFilter))
    }

    const handleFilterCalendar = (value) => {
        const dataFilter = {
            page: 1,
            data: {
                start_time: (value[0] || value).toDateString(),
                end_time: (value[1] || value).toDateString(),
                gamesite_id: gamesiteFilter || '',
                agent_id: agentFilter || '',
            }
        }

        dispatch(filterAnalysisList(dataFilter))
    }

    return (
        <section className='grid gap-4'>
            <div className='flex justify-between lg:items-center max-lg:flex-col gap-5'>
                <h3 className='lg:text-text20-24 text-text16-20 font-bold'>{KR_ADMIN_ANALYSIS_TITLE_SECTION}</h3>

                <div className='flex gap-2.5 max-lg:flex-col'>
                    <FilterCalendar
                        handleSelectDay={handleFilterCalendar}
                        className='max-lg:w-full aspect-auto'
                        classDropdown='max-lg:left-0 max-lg:!-translate-x-[20px]'
                        classNameButton='max-lg:w-full'
                    />

                    <Dropdown
                        title='시간 필터'
                        isSelect={time}
                        data={filterData}
                        handleClick={filterByDate}
                        classDropdown='max-lg:left-0'
                        className='max-lg:w-full justify-between'
                    />

                    {
                        !(((userRole || role) === 'agent-admin') || (userRole || role) === 'game-admin')
                        &&
                        <Dropdown
                            title='Agent'
                            isSelect={agentFilter}
                            data={filter.agent}
                            name='agent_name'
                            id='agent_id'
                            handleClick={filterByAgent}
                            classDropdown='max-lg:left-0'
                            className='max-lg:w-full justify-between'
                        />
                    }

                    {
                        !((userRole || role) === 'game-admin')
                        &&
                        <Dropdown
                            title='Gamesite'
                            isSelect={gamesiteFilter}
                            data={filter.gamesite}
                            handleClick={filterByGamesite}
                            name='gamesite_name'
                            id='gamesite_id'
                            classDropdown='max-lg:left-0'
                            className='max-lg:w-full justify-between'
                        />
                    }
                </div>
            </div>

            <div className='p-5 rounded-20 bg-darkblue flex justify-between text-text20-24 max-lg:flex-col gap-2.5'>
                <h3 className='font-bold max-lg:text-text14-20'>{KR_ADMIN_ANALYSIS_SETTLEMENTTOTAL}</h3>

                <div className='flex gap-10 items-center max-lg:text-text14-20'>
                    <p>{KR_USER_TITLE_DEPOSIT} {analysisTotal.desposit}</p>
                    <p>{KR_USER_TITLE_WITHDRAW} {analysisTotal.withdraw}</p>
                </div>
            </div>

            <div className='p-5 pb-[5px] bg-darkblue rounded-20 overflow-x-auto'>
                <table className='w-full text-center'>
                    <thead>
                        <tr className='text-text16-20 font-bold '>
                            <th className='pb-4 max-lg:text-text14-20 min-w-[200px]'>거래 이름</th>
                            {
                                !(((userRole || role) === 'agent-admin') || (userRole || role) === 'game-admin')
                                &&
                                <th className='pb-4 max-lg:text-text14-20 min-w-[130px]'>Agent</th>
                            }
                            {
                                !((userRole || role) === 'game-admin')
                                &&
                                <th className='pb-4 max-lg:text-text14-20 min-w-[130px]'>Gamesite</th>
                            }

                            <th className='pb-4 max-lg:text-text14-20 min-w-[130px]'>user ID</th>
                            <th className='pb-4 max-lg:text-text14-20 min-w-[120px]'>상세날짜</th>
                            <th className='pb-4 max-lg:text-text14-20 min-w-[150px]'>입금(USDT)</th>
                            <th className='pb-4 max-lg:text-text14-20 min-w-[150px]'>출금(USDT)</th>
                            <th className='pb-4 max-lg:text-text14-20 min-w-[200px]'>지갑주소</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataAnalysis.map(item => <RowAnalysisItem key={item.id} props={item} />)
                        }
                    </tbody>
                </table>
            </div>
        </section>
    )
}

const RowAnalysisItem = ({ props }) => {
    const { id, name, agent, desposit, withdraw, receiving_wallet, created_at, gamesite, user_id } = props

    const { userRole } = useSelector(state => state.auth)
    const role = getLocalStorage('role')

    return (
        <tr className='border-t border-stroke max-lg:text-text14-20'>
            <td className='py-4 px-1'>{name}</td>
            {
                !(((userRole || role) === 'agent-admin') || (userRole || role) === 'game-admin')
                &&
                <td className='py-4 px-1 '>{agent}</td>
            }

            {
                !((userRole || role) === 'game-admin')
                &&
                <td className='py-4 px-1 '>{gamesite}</td>
            }

            <td className='py-4 px-1 '>{user_id}</td>
            <td className='py-4 px-1 '>
                <div>
                    <p>{moment(created_at).format('YYYY.MM.DD')}</p>
                    <p>{moment(created_at).format('hh:mm A')}</p>
                </div>
            </td>
            <td className='py-4 px-1 w-[150px]'>
                <p className='line-clamp-1 break-all'>{desposit}</p>
            </td>
            <td className='py-4 px-1 w-[150px]'>
                <p className='line-clamp-1 break-all'>{withdraw}</p>
            </td>
            <td className='py-4 px-1 w-[200px]'>
                <p className='break-all'>{receiving_wallet}</p>
            </td>
        </tr>
    )
}
