import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { CREATE, KR_ADMIN_USER_SIDEBAR } from '../../translations/kor'
import ListUserAdmin from '../../sections/admin/Users/ListUserAdmin'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllUser } from '../../reduxs/actions/user.action'
import Loading from '../../components/Loading'
import ButtonPrimary from '../../components/ButtonPrimary'
import CreateNewUser from '../../components/CreateNewUser'

export default function UserAdminPage() {
    const dispatch = useDispatch()

    const [isShowCreateUser, setIsShowCreateUser] = useState(false)

    const { game } = useParams()

    const { loadingGetUser, dataUsers, totalCount } = useSelector(state => state.user)

    // show create user
    const handleShowCreateUser = () => {
        setIsShowCreateUser(!isShowCreateUser)
    }

    useEffect(() => {
        dispatch(getAllUser(game || ''))
    }, [dispatch, game])

    if (loadingGetUser || !dataUsers) {
        return <Loading />
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitlePage title={KR_ADMIN_USER_SIDEBAR} />
                <ButtonPrimary title={`${CREATE} +`} onClick={handleShowCreateUser} type='button' />
            </div>

            <section className='grid lg:grid-cols-3 lg:gap-4 gap-2.5'>
                {
                    totalCount.map((item, index) => <TotalUserItem key={index} title={item.title} value={item.count} />)
                }
            </section>

            <ListUserAdmin data={dataUsers} />

            <CreateNewUser handleShow={handleShowCreateUser} show={isShowCreateUser} />
        </>
    )
}

const TotalUserItem = ({ title, value }) => {
    return (
        <div className='p-5 text-center rounded-20 bg-darkblue'>
            <p className='lg:mb-4 mb-2.5 lg:text-text16-26 text-text14-20'>{title}</p>
            <p className='lg:text-text40-50 text-text20-24 font-bold'>{value}</p>
        </div>
    )
}