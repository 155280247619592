import React, { useEffect } from 'react'
import avatar from '../../assets/images/banner.avif'
import { IoIosSearch } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getInforAccount } from '../../reduxs/actions/auth.action'
import { LOGO } from '../../translations/kor'
import { Link, useParams } from 'react-router-dom'
import MenuShowSidebar from './MenuShowSidebar'
import Notifycation from './Notifycation'

export default function HeaderAdmin() {
    const dispatch = useDispatch()

    const { nameUser, email } = useSelector(state => state.auth)

    const { game } = useParams()

    useEffect(() => {
        dispatch(getInforAccount())
    }, [])

    return (
        <header className='p-5 bg-bg flex justify-between lg:items-center gap-5'>
            <h1 className='font-bold lg:hidden'>
                <Link to={game ? `/admin/${game}` : '/admin'}>
                    {LOGO}
                </Link>
            </h1>


            <form className='lg:max-w-[790px] w-full relative max-lg:hidden'>
                <IoIosSearch className='absolute text-deactivate text-xl -translate-y-1/2 top-1/2 lg:left-4 left-3' />
                <input type='text' className='w-full lg:py-2 lg:px-4 py-1.5 px-3 rounded-12 text-black lg:pl-10' placeholder='검색' />
            </form>

            <div className='flex gap-5 items-center'>
                <div className='flex items-center gap-2.5 '>
                    <Notifycation />
                    <MenuShowSidebar nameUser={nameUser} email={email} />
                </div>

                <div className='flex gap-2 items-center max-lg:hidden'>
                    <img src={avatar} alt='' className='w-[42px] aspect-square rounded-full object-cover block' />

                    <div>
                        <p className='text-text16-20 font-bold'>{nameUser}</p>
                        <span className='text-text14-20'>{email}</span>
                    </div>
                </div>
            </div>

        </header>
    )
}
