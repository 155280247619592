import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../utils/api";

export const getAnalysisList = createAsyncThunk(
    'analysis/getall',
    async (page) => {
        const response = await get(`admin/total_analysis/${page}`)

        return response
    }
)

export const filterAnalysisList = createAsyncThunk(
    'analysis/filteranalysis',
    async (dataUpload) => {
        const { page, data } = dataUpload

        const response = await post(`admin/filter_analysis/${page}`, data)

        return response
    }
)
