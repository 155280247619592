import React, { useState } from 'react'
import {
    KR_ADMIN_TITLE_HISTORY_DASHBOARD,
    KR_ADMIN_TRANSACTION_AMOUNT_TABLE,
    KR_ADMIN_TRANSACTION_DATE_TABLE,
    KR_ADMIN_TRANSACTION_NAME_TABLE,
    KR_ADMIN_TRANSACTION_STATUS_TABLE,
    KR_USER_ALL_TRANSACTION,
    KR_USER_FILTER_TRANSACTION,
    KR_USER_IN_TRANSACTION,
    KR_USER_OUT_TRANSACTION
} from '../../../translations/kor'
import moment from 'moment'
import statusComponent from '../../../components/StatusComponent'
import DetailDepositItem from '../../../pages/admin/DetailDepositItem'
import { PopupAdmin } from '../../../components/user/Popup'
import { IoIosTrendingUp } from 'react-icons/io'
import Dropdown from '../../../components/Dropdown'

const dataFilter = [
    {
        id: 0,
        name: KR_USER_ALL_TRANSACTION
    },
    {
        id: 1,
        name: KR_USER_IN_TRANSACTION
    },
    {
        id: 2,
        name: KR_USER_OUT_TRANSACTION
    },
]

export default function LastTransaction({ data }) {
    const [isSelectFilter, setIsSelectFilter] = useState(0)

    // filter status
    const handleSelectFilter = (filter) => {
        setIsSelectFilter(filter.id)
    }

    return (
        <section className='w-full'>
            <div className='flex justify-between items-center lg:mb-4 mb-2.5'>
                <h3 className='lg:text-text20-24 text-text16-20 font-bold lg:mb-4 mb-2.5'>{KR_ADMIN_TITLE_HISTORY_DASHBOARD}</h3>

                <Dropdown data={dataFilter} title={KR_USER_FILTER_TRANSACTION} isSelect={isSelectFilter} handleClick={handleSelectFilter} />
            </div>

            <div className='p-5 pb-[5px] bg-darkblue rounded-20 overflow-x-auto'>
                <table className='w-full'>
                    <thead>
                        <tr className='text-text16-20 font-bold '>
                            <th className='pb-4 text-start min-w-[160px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_NAME_TABLE}</th>
                            <th className='pb-4 text-center min-w-[150px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_DATE_TABLE}</th>
                            <th className='pb-4 text-center min-w-[100px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_STATUS_TABLE}</th>
                            <th className='pb-4 text-end min-w-[120px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_AMOUNT_TABLE}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            data.map(item => {
                                if (!isSelectFilter || (item.type_transaction_id === isSelectFilter)) {
                                    return <RowTransactionItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        created_at={item.created_at}
                                        amount={item.amount}
                                        status_id={item.status_id}
                                        currency_name={item.currency_name}
                                        typeTransaction={item.type_transaction_id === 1 ? true : false}
                                    />
                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
        </section>
    )
}

export const RowTransactionItem = ({ id, name, status_id, amount, created_at, currency_name, typeTransaction }) => {
    const [isShow, setIsShow] = useState(false)

    const handleShow = () => {
        setIsShow(!isShow)
    }

    return (
        <>
            <tr className='border-t border-stroke'>
                <td className='py-4'>
                    <div className='cursor-pointer flex items-center gap-2 max-lg:text-text14-20' onClick={handleShow}>
                        <IoIosTrendingUp className={`flex-shrink-0 ${typeTransaction && 'rotate-180'}`} />

                        {name}
                    </div>

                    {
                        isShow
                        &&
                        <PopupAdmin handleShow={handleShow} show={isShow}>
                            <DetailDepositItem id={id} />
                        </PopupAdmin>

                    }
                </td>
                <td className='py-4 text-center cursor-pointer max-lg:text-text14-20' onClick={handleShow}>{moment(created_at).format('YYYY.MM.DD - hh:mm A')}</td>
                <td className='py-4 text-center flex justify-center cursor-pointer' onClick={handleShow}>{statusComponent(status_id)}</td>
                <td className={`py-4 text-end cursor-pointer ${!typeTransaction && 'text-red'} max-lg:text-text14-20`} onClick={handleShow}>{!typeTransaction && '-'}{amount} {currency_name}</td>
            </tr>

        </>
    )
}
