import React, { useEffect, useState } from 'react'
import { BsChevronExpand } from 'react-icons/bs'
import {
    ERROR_REQUIRE,
    KR_USER_COMPANY_NOTIFY_DEPOSIT,
    KR_USER_COPY_DEPOSIT,
    KR_USER_MYWALLET_NOTIFY_DEPOSIT,
    KR_USER_SUBMIT_DEPOSIT,
    KR_USER_TITLE_COMPANY_DEPOSIT,
    KR_USER_TITLE_MYWALLET_DEPOSIT
} from '../../../translations/kor'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ConfirmIdTransaction from '../../../sections/user/ConfirmIdTransaction'
import { useDispatch, useSelector } from 'react-redux'
import { getdeposit } from '../../../reduxs/actions/deposit.action'
import { useParams } from 'react-router-dom'
import Loading from '../../../components/Loading'
import { URL_SERVER_IMAGE } from '../../../utils/api'
import DropdownDeposit from '../../../components/DropdownDeposit'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { copytext } from '../../../utils/handle'
import InputChangeForm from '../../../components/InputChangeForm'

export default function DetailDeposit() {
    const dispatch = useDispatch()

    const [isShowPopup, setIsShowPopup] = useState(false)
    const [valuePoint, setValuePoint] = useState(1)
    const [isSelectedtoken, setIsSelectedtoken] = useState()

    const [confirmPassword, setConfirmPassword] = useState(false)

    const { game, id } = useParams()

    const { loadGetDeposit, dataDeposits } = useSelector(state => state.deposit)

    const handleShow = () => {
        setIsShowPopup(!isShowPopup)
    }

    // select token
    const handleSelectToken = (token) => {
        setIsSelectedtoken(token)
        formik.setFieldValue('token_id', token.id)
        formik.setFieldValue('receiving_wallet', token.token_address_user)
        formik.setFieldValue('sending_wallet', token.token_address)
    }

    // change point value
    const handleChangePoint = (e) => {
        if (e.target.value <= 0) {
            formik.setFieldValue('amount', 0)
            return setValuePoint(0)
        }

        formik.setFieldValue('amount', e.target.value)
        setValuePoint(e.target.value)
    }

    // form submit deposit
    const formik = useFormik({
        initialValues: {
            token_id: '',
            receiving_wallet: '',
            sending_wallet: '',
            amount: '',
            'TXID/HASH': ''
        },
        validationSchema: Yup.object({
            token_id: Yup.string().required(ERROR_REQUIRE),
            receiving_wallet: Yup.string().required(ERROR_REQUIRE),
            sending_wallet: Yup.string().required(ERROR_REQUIRE),
            amount: Yup.string().required(ERROR_REQUIRE),
            'TXID/HASH': Yup.string()
        }),
        onSubmit: values => {
            if (!confirmPassword) {
                setIsShowPopup(true)
            }
        }
    })

    useEffect(() => {
        dispatch(getdeposit(game))
    }, [dispatch, game])

    useEffect(() => {
        if (dataDeposits) {
            const deposit = dataDeposits.rate.filter(item => (item.id).toString() === id.toString())[0]
            setIsSelectedtoken(deposit)

            formik.setFieldValue('token_id', deposit.id)
            formik.setFieldValue('receiving_wallet', deposit.token_address_user)
            formik.setFieldValue('sending_wallet', deposit.token_address_company)
            formik.setFieldValue('amount', 1)
        }
    }, [dataDeposits, id])

    if (loadGetDeposit || !dataDeposits) {
        return <Loading />
    }

    const { gamepoint, rate, gamepoint_rate } = dataDeposits

    return (
        <>
            <section className='rounded-16 bg-white text-black p-5 flex flex-col gap-4 items-center flex-grow' onSubmit={formik.handleSubmit}>
                <img src={`${URL_SERVER_IMAGE}${isSelectedtoken?.QAcode_company}`} alt='' className='w-[140px] aspect-square object-contain' />

                <div className='w-full flex flex-col items-center gap-2'>
                    <DropdownDeposit
                        data={rate}
                        isSelected={isSelectedtoken}
                        handleSelectToken={handleSelectToken}
                        value={valuePoint}
                        handleChangePoint={handleChangePoint}
                    />

                    <BsChevronExpand />

                    <div className='flex justify-between items-center w-full p-3 bg-lightBlue rounded-12'>
                        <div className='flex items-center gap-2'>
                            <img src={`${URL_SERVER_IMAGE}${gamepoint.logo_gamepoint}`} alt='icon' className='w-[26px] aspect-square rounded-full object-contain' />

                            <span className='text-text14-20'>{gamepoint.gamepoint_name}</span>
                        </div>

                        <p className='text-text14-20'>{gamepoint_rate !== 0 ? parseFloat(((gamepoint_rate / isSelectedtoken?.value_token) * valuePoint).toFixed(3)) : 0}</p>
                    </div>
                </div>

                <div className='w-full flex flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_TITLE_COMPANY_DEPOSIT}</h3>

                    <div className='flex gap-3 justify-between items-center bg-lightBlue rounded-12 p-3'>
                        <p className='break-all text-text14-20'>{isSelectedtoken?.token_address_company}</p>

                        <ButtonPrimary title={KR_USER_COPY_DEPOSIT} onClick={() => copytext(isSelectedtoken?.token_address_company)} className='flex-shrink-0 text-white text-text10-12 !py-1 !px-3' />
                    </div>

                    <p className='text-text10-12 text-red'>{KR_USER_COMPANY_NOTIFY_DEPOSIT}</p>
                </div>

                <div className='w-full flex justify-between flex-col gap-2'>
                    <h3 className='text-text14-20 font-bold'>{KR_USER_TITLE_MYWALLET_DEPOSIT}</h3>

                    <InputChangeForm
                        name='receiving_wallet'
                        value={formik.values.receiving_wallet}
                        onChange={formik.handleChange}
                        error={(formik.touched.receiving_wallet && formik.errors.receiving_wallet) && formik.errors.receiving_wallet}
                    />

                    <p className='text-text10-12 text-red'>{KR_USER_MYWALLET_NOTIFY_DEPOSIT}</p>
                </div>

                <ButtonPrimary title={KR_USER_SUBMIT_DEPOSIT} className='w-full text-white' onClick={formik.handleSubmit} />

                <ConfirmIdTransaction handleShow={handleShow} show={isShowPopup} dataSubmit={formik} />
            </section>
        </>
    )
}
