import React, { useEffect, useRef, useState } from 'react'
import { IoGridOutline, IoLogOutOutline, IoMenu, IoPeopleOutline, IoWalletOutline } from 'react-icons/io5'
import avatar from '../../assets/images/banner.avif'
import { NavLink, useParams } from 'react-router-dom'
import { KR_ADMIN_ACCOUNT_SIDEBAR, KR_ADMIN_ANALYSIS_SIDEBAR, KR_ADMIN_BANNER_SIDEBAR, KR_ADMIN_BLOG_SIDEBAR, KR_ADMIN_DASHBOARD_SIDEBAR, KR_ADMIN_GENERAL_SIDEBAR, KR_ADMIN_HISTORY_SIDEBAR, KR_ADMIN_LOGOUT_SIDEBAR, KR_ADMIN_SETTING_SIDEBAR, KR_ADMIN_USER_SIDEBAR, KR_ADMIN_WALLET_SIDEBAR } from '../../translations/kor'
import { BsBank, BsMenuButton } from 'react-icons/bs'
import { IoIosGlobe } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalStorage } from '../../utils/localStorage'
import { FaRegUser } from 'react-icons/fa'
import { logout } from '../../reduxs/actions/auth.action'
import { Chart } from 'iconsax-react'

export default function MenuShowSidebar({ nameUser, email }) {
    const dispatch = useDispatch()

    const contentRef = useRef()

    const [isShow, setIsShow] = useState(false)

    const { userRole } = useSelector(state => state.auth)
    const role = getLocalStorage('role')

    const { game } = useParams()

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleShow = () => {
        setIsShow(!isShow)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShow()
            }
        }

        if (isShow) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShow]);

    return (
        <>
            <button onClick={handleShow} type='button' className='lg:hidden '><IoMenu /></button>

            <div className={`fixed inset-0 ${isShow ? 'bg-bgOpacity' : 'invisible'} duration-300 z-50 flex justify-end`}>
                <div className={`w-8/12 h-full rounded-l-24 bg-bg p-5 flex flex-col justify-between duration-300 ${isShow ? '' : 'translate-x-full'}`} ref={contentRef}>
                    <div className=' flex flex-col gap-6'>
                        <div className='flex gap-1 items-center'>
                            <img src={avatar} alt='' className='w-[30px] aspect-square rounded-full object-cover block' />

                            <div className='flex flex-col'>
                                <p className='text-text14-20 font-bold'>{nameUser}</p>
                                <span className='text-text10-12'>{email}</span>
                            </div>
                        </div>

                        <nav className='flex flex-col gap-4'>
                            <h4 className='text-text14-20 font-bold'>{KR_ADMIN_GENERAL_SIDEBAR}</h4>

                            <ul className='grid gap-4'>
                                <NavItem icon={<IoGridOutline />} title={KR_ADMIN_DASHBOARD_SIDEBAR} link={game ? `/admin/${game}` : '/admin'} end={true} />
                                <NavItem icon={<IoWalletOutline />} title={KR_ADMIN_WALLET_SIDEBAR} link={`wallets`} />
                                <NavItem icon={<BsBank />} title={KR_ADMIN_HISTORY_SIDEBAR} link='historys' />
                                <NavItem icon={<Chart size={16} />} title={KR_ADMIN_ANALYSIS_SIDEBAR} link='analysis' />
                                <NavItem icon={<IoPeopleOutline />} title={KR_ADMIN_USER_SIDEBAR} link='users' />

                                {
                                    (
                                        ((userRole || role) === 'game-admin')
                                        ||
                                        ((userRole || role) === 'total-admin')
                                    )
                                    &&
                                    <NavItem icon={<IoIosGlobe />} title={KR_ADMIN_BANNER_SIDEBAR} link='banners' />
                                }

                                {
                                    (userRole || role) === 'total-admin'
                                    &&
                                    <>
                                        <NavItem icon={<BsMenuButton />} title={KR_ADMIN_BLOG_SIDEBAR} link='blogs' />
                                    </>
                                }
                            </ul>
                        </nav>

                        <div className='flex flex-col gap-4'>
                            <h4 className='text-text14-20 font-bold'>{KR_ADMIN_SETTING_SIDEBAR}</h4>

                            <ul>
                                <NavItem icon={<FaRegUser />} title={KR_ADMIN_ACCOUNT_SIDEBAR} link='my-account' />
                            </ul>
                        </div>
                    </div>

                    <button onClick={handleLogout} className='flex items-center gap-2 text-text16-26 py-3 px-4 rounded-12 duration-300 hover:bg-blue'>
                        <IoLogOutOutline />

                        <span>{KR_ADMIN_LOGOUT_SIDEBAR}</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export const NavItem = ({ icon, title, link = '#', end = false }) => {
    const style = 'flex items-center gap-2 text-text14-20 py-1.5 px-3 rounded-8 duration-300 hover:bg-blue'

    return (
        <li>
            <NavLink to={link} className={({ isActive, isPending }) => isActive ? `${style} bg-blue` : `${style}`} end={end}>
                {icon}

                <span>{title}</span>
            </NavLink>
        </li>
    )
}
