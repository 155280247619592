import { createSlice } from "@reduxjs/toolkit";
import { filterAnalysisList, getAnalysisList } from "../actions/analysis.action";

const analysisSlice = createSlice({
    name: 'analysis',
    initialState: {
        loadingGetAnalysis: false,
        dataAnalysis: undefined,
        analysisTotal: undefined,
        filterAnalysis: undefined,
        totalListdata: undefined,

        loadingFilterAnalysis: false,
    },
    extraReducers: builder => {
        builder.addCase(getAnalysisList.pending, (state, action) => {
            state.loadingGetAnalysis = true
        })
        builder.addCase(getAnalysisList.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.analysisTotal = res.data.analysis
                state.dataAnalysis = res.data.history_transaction
                state.filterAnalysis = res.data.listFilter
                state.totalListdata = res.data.total_in_listdata
            }

            state.loadingGetAnalysis = false
        })

        builder.addCase(filterAnalysisList.pending, (state, action) => {
            state.loadingFilterAnalysis = true
        })
        builder.addCase(filterAnalysisList.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataAnalysis = res.data.history_transaction
                state.totalListdata = res.data.total_in_listdata
            }

            state.loadingFilterAnalysis = false
        })
    }
})

export const { } = analysisSlice.actions
export default analysisSlice.reducer
