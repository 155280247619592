import React, { useEffect, useRef, useState } from 'react'
import { Calendar } from 'react-calendar'

import 'react-calendar/dist/Calendar.css';
import ButtonPrimary from './ButtonPrimary';
import { IoCalendarOutline } from 'react-icons/io5';

const today = new Date()

export default function FilterCalendar({ handleSelectDay, className, classDropdown, title, classNameButton }) {
    const contentRef = useRef()

    const [isShowCalendar, setIsShowCalendar] = useState(false)
    const [value, setValue] = useState(today)

    // handle show calendar
    const handleShowCalendar = () => {
        setIsShowCalendar(!isShowCalendar)
    }

    // click outsize
    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShowCalendar()
            }
        }

        if (isShowCalendar) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, handleShowCalendar, isShowCalendar]);

    return (
        <div className={`relative aspect-square ${className}`} ref={contentRef}>
            <ButtonPrimary title={<>{title} < IoCalendarOutline /></>} onClick={handleShowCalendar} className={`h-full max-lg:!p-1 flex justify-center items-center ${classNameButton}`} />

            {
                isShowCalendar
                &&
                <div className={`z-10 absolute top-full right-0 mt-1 text-black shadow-shadow-sidebar p-5 bg-white rounded-8 max-sm:translate-x-[106px] max-sm:w-screen ${classDropdown}`}>
                    <Calendar
                        onChange={(e) => {
                            handleSelectDay(e)
                            setValue(e)
                        }}
                        value={value}
                        className='!border-none max-sm:!w-full'
                        locale='ko-KO'
                        onClickDay={handleSelectDay}
                        calendarType='iso8601'
                        formatDay={(locale, date) => date.getDate()}
                        maxDate={new Date()}
                        selectRange={true}
                    />
                </div>
            }
        </div>
    )
}
