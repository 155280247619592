import moment from "moment/moment";

export const handlegetTime = (date) => {
    const now = moment();

    let dateTime = null

    switch (date) {
        case 1:
            return (dateTime = {
                start_time: now.startOf('day').toString(),
                end_time: now.endOf('day').toString(),
            })

        case 2:
            const yesterday = moment().subtract(1, 'days');

            return (dateTime = {
                start_time: yesterday.startOf('day').toString(),
                end_time: yesterday.endOf('day').toString(),
            })

        case 3:
            return (dateTime = {
                start_time: now.startOf('week').toString(),
                end_time: now.endOf('week').toString(),
            })

        case 4:
            return (dateTime = {
                start_time: now.startOf('month').toString(),
                end_time: now.endOf('month').toString(),
            })

        case 5:
            const lastMonth = moment().subtract(1, 'months');

            return (dateTime = {
                start_time: lastMonth.startOf('month').toString(),
                end_time: lastMonth.endOf('month').toString(),
            })

        default:
            return (dateTime = {
                start_time: '',
                end_time: '',
            })
    }
}
