import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { KR_ADMIN_HISTORY_SIDEBAR, KR_ADMIN_TRANSACTION_AMOUNT_TABLE, KR_ADMIN_TRANSACTION_DATE_TABLE, KR_ADMIN_TRANSACTION_NAME_TABLE, KR_ADMIN_TRANSACTION_STATUS_TABLE, KR_USER_ALL_TRANSACTION, KR_USER_FILTER_TRANSACTION, KR_USER_IN_TRANSACTION, KR_USER_OUT_TRANSACTION } from '../../translations/kor'
import { RowTransactionItem } from '../../sections/admin/Dashboard/LastTransaction'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { filterTransaction, getAllTransaction } from '../../reduxs/actions/transaction.action'
import Loading from '../../components/Loading'
import Dropdown from '../../components/Dropdown'
import FilterCalendar from '../../components/FilterCalendar'

const dataFilter = [
    {
        id: 0,
        name: KR_USER_ALL_TRANSACTION
    },
    {
        id: 1,
        name: KR_USER_IN_TRANSACTION
    },
    {
        id: 2,
        name: KR_USER_OUT_TRANSACTION
    },
]

export default function HistoryPage() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const [isSelectFilter, setIsSelectFilter] = useState(0)

    const { dataTransaction, loadingGetTransaction } = useSelector(state => state.transaction)

    // filter status
    const handleSelectFilter = (filter) => {
        setIsSelectFilter(filter.id)
    }

    // filter select day
    const handleSelectDay = (value) => {
        const dataSubmit = {
            start_time: (value[0] || value).toDateString(),
            end_time: (value[1] || value).toDateString(),
            namesite: game
        }

        dispatch(filterTransaction(dataSubmit))

        // console.log(dataSubmit)
    }

    useEffect(() => {
        dispatch(getAllTransaction(game || ''))
    }, [game])

    if (loadingGetTransaction || !dataTransaction) {
        return <Loading />
    }

    return (
        <>
            <div className='flex justify-between items-center'>
                <TitlePage title={KR_ADMIN_HISTORY_SIDEBAR} />

                <div className='flex items-stretch gap-2.5'>
                    <FilterCalendar handleSelectDay={handleSelectDay} />
                    <Dropdown data={dataFilter} title={KR_USER_FILTER_TRANSACTION} isSelect={isSelectFilter} handleClick={handleSelectFilter} />
                </div>
            </div>

            <section className='p-5 pb-[5px] bg-darkblue rounded-20 overflow-x-auto'>
                <table className='w-full'>
                    <thead>
                        <tr className='text-text16-20 font-bold '>
                            <th className='pb-4 text-start min-w-[160px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_NAME_TABLE}</th>
                            <th className='pb-4 text-center min-w-[150px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_DATE_TABLE}</th>
                            <th className='pb-4 text-center min-w-[100px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_STATUS_TABLE}</th>
                            <th className='pb-4 text-end min-w-[120px] max-lg:text-text14-20'>{KR_ADMIN_TRANSACTION_AMOUNT_TABLE}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            dataTransaction.map(item => {
                                if (!isSelectFilter || (item.type_transaction_id === isSelectFilter)) {
                                    return <RowTransactionItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        amount={item.amount}
                                        created_at={item.created_at}
                                        type_transaction_id={item.type_transaction_id}
                                        status_id={item.status_id}
                                        currency_name={item.currency_name}
                                        typeTransaction={item.type_transaction_id === 1 ? true : false}
                                    />
                                }
                            })
                        }
                    </tbody>
                </table>
            </section>
        </>
    )
}
