/////// USER ///////
export const LOGO = 'GameWallet'

export const FIX = '수정'
export const DELETE = '삭제'
export const CREATE = '신규 생성'

// ======= LOGIN PAGE =======
export const KR_USER_TITLE_LOGIN = 'GameWallet'
export const KR_USER_SUBTITLE_LOGIN = '로그인'

export const KR_USER_NEXT_STEP_LOGIN = '다음'

export const KR_USER_PLACERHODER_LOGIN = '아이디를 입력하세요.'

export const KR_USER_ACCOUNT_LOGIN = '아이디'
export const KR_USER_ACCOUNT_INPUT_LOGIN = 'ID 입력'

export const KR_USER_PASSWORD_LOGIN = '비밀번호'
export const KR_USER_PASSWORD_INPUT_LOGIN = '비밀번호 입력하세요'

export const KR_USER_FORGOT_LOGIN = '비밀번호를 잊으셨나요?'
export const KR_USER_SUBMIT_LOGIN = '확인'

export const KR_USER_NOTACCOUNT_LOGIN = '계정이 없나요?'
export const KR_USER_SIGNUP_LOGIN = '확인'

// ====== CONFIRM PAGE ======
export const KR_USER_TELEGRAM_CONFIRM = '텔레그램 아이디'
export const KR_USER_TELEGRAM_PLACEHODER_CONFIRM = '텔레그램 아이디를 입력하세요(선택사항)'

export const KR_USER_EMAIL_CONFIRM = '이메일'
export const KR_USER_EMAIL_PLACEHODER_CONFIRM = '이메일을 입력하세요(선택사항)'

export const KR_USER_CONFIRM_PASSWORD_LOGIN = '비밀번호 확인'
export const KR_USER_CONFIRM_PASSWORD_PLACEHODER_LOGIN = '비밀번호 입력하세요'

// ======= FORGOT PASSWORD PAGE =======
export const KR_USER_SUBTITLE_FORGOT = '비밀번호를 잊으셨나요?'

// ======= OTP PAGE =======
export const KR_USER_SUBTITLE_OTP = 'OTP'
export const KR_USER_INPUT_OTP = ' OTP 입력해주세요'

// ======= OTP PAGE =======
export const KR_USER_SUBTITLE_NEWPASS = '비밀번호 변경'

export const KR_USER_PASSWORD_NEWPASS = '비밀번호 변경'
export const KR_USER_PASSWORD_INPUT_NEWPASS = '비밀번호 변경'

export const KR_USER_CONFIRM_PASSWORD_NEWPASS = '비밀번호 변경'
export const KR_USER_CONFIRM_PASSWORD_INPUT_NEWPASS = '비밀번호 변경'

// ======= SCURITY CODE ======= 
export const KR_USER_TITLE_SECURITY_CODE = '보안코드'
export const KR_USER_PLACEHODER_SECURITY_CODE = '4자리 숫자를 입력하세요(필수사항)'

// ======= DASHBOARD PAGE =======
export const KR_USER_HELLO_DASHBOARD = '안녕하세요,'
export const KR_USER_PORT_DASHBOARD = '최근 게시물'
export const KR_USER_LOGOUT = '로그아웃'

export const KR_USER_WARNING_DASHBOARD = '주의사항'

// ======= DEPOSIT PAGE =======

export const KR_USER_TITLE_DEPOSIT = '입금'
export const KR_USER_EXCHANGE_DEPOSIT = '오늘의 환율'
export const KR_USER_CANCEL_DEPOSIT = '닫기'

// ======= DETAIL DEPOSIT PAGE =======
export const KR_USER_TITLE_COMPANY_DEPOSIT = '게임회사 지갑'
export const KR_USER_COMPANY_NOTIFY_DEPOSIT = '최소 입금액은 10.00 USDT입니다.'

export const KR_USER_TITLE_MYWALLET_DEPOSIT = '내지갑'
export const KR_USER_MYWALLET_NOTIFY_DEPOSIT = 'USDT 송금 후 TXID/HASH를 입력해주세요.'

export const KR_USER_COPY_DEPOSIT = '복사'
export const KR_USER_FIX_DEPOSIT = '수정'

export const KR_USER_SUBMIT_DEPOSIT = 'TXID 입력'
export const KR_USER_CONFIRM_DEPOSIT = '확인'
export const KR_USER_OFF_DEPOSIT = '취소'

export const KR_USER_TITLE_CONFIRMID_DEPOSIT = '전송 ID (TXID/HASH)를 입력'
export const KR_USER_CONFIRMID_NOTIFY_DEPOSIT = 'USDT를 송금 후에는 반드시 거래 ID를 생성하세요'

// ======= WITHDRAW PAGE =======
export const KR_USER_TITLE_WITHDRAW = '출금'
export const KR_USER_REQUEST_WITHDRAW = '요청사항'
export const KR_USER_REQUEST_INPUT_WITHDRAW = '요청 입력하세요....'

// ======= TRANSACTION PAGE =======
export const KR_USER_TITLE_TRANSACTION = '전송내역'
export const KR_USER_NAME_TABLE_TRANSACTION = '입출금내역'
export const KR_USER_AMOUNT_TABLE_TRANSACTION = '금액'

export const KR_USER_FILTER_TRANSACTION = '필터'

export const KR_USER_ALL_TRANSACTION = '모두'
export const KR_USER_IN_TRANSACTION = '입금'
export const KR_USER_OUT_TRANSACTION = '출금'

// ======= POSTS PAGE =======
export const KR_USER_TITLE_POSTS = '게시물'
export const KR_USER_VIEWMORE_POSTS = '더보기'

// ======= MYWALET PAGE =======
export const KR_USER_TITLE_MYWALET = '마이페이지'
export const KR_USER_TELEGRAM_MYWALET = '텔레그램 아이디'
export const KR_USER_EMAIL_MYWALET = '이메일'
export const KR_USER_PASSWORD_MYWALET = '비밀번호'

export const KR_USER_TITLE_PASSWORD_MYWALET = '비밀번호 변경'
export const KR_USER_TITLE_EMAIL_MYWALET = '이메일 수정'
export const KR_USER_TITLE_CODE_MYWALET = '보안 코드 수정'
export const KR_USER_TITLE_TELEGRAM_MYWALET = '텔레그램 수정'

export const KR_USER_LABEL_NEWCODE_MYWALET = '보안 코드'
export const KR_USER_INPUT_NEWCODE_MYWALET = '새 비밀번호 입력해주세요'

export const KR_USER_LABEL_CONFIRMPASS_MYWALET = '새 비밀번호'
export const KR_USER_INPUT_CONFIRMPASS_MYWALET = '새 비밀번호 입력해주세요'

export const KR_USER_LABEL_EMAIL_MYWALET = '이메일'
export const KR_USER_INPUT_EMAIL_MYWALET = '이메일 입력하세요'

export const KR_USER_LABEL_TELEGRAM_MYWALET = '텔레그램'
export const KR_USER_INPUT_TELEGRAM_MYWALET = '텔레그램 입력하세요'

export const KR_USER_CHANGE_TOKEN_MYWALET = '지갑주소 수정'

/////// ADMIN ///////

// ======= LOGIN PAGE =======
export const KR_ADMIN_TITLE_LOGIN = '로그인'

export const KR_ADMIN_NOTIFY_LOGIN = '알림'

export const KR_ADMIN_DEPOSIT_DASHBOARD = '오늘 입금'
export const KR_ADMIN_DEPOSIT_TODAY_DASHBOARD = '오늘 출금'

export const KR_ADMIN_WITHDRAW_DASHBOARD = '전체 입금'
export const KR_ADMIN_WITHDRAW_TODAY_DASHBOARD = '전체 출금'

export const KR_ADMIN_POINT_DASHBOARD = '건'

export const KR_ADMIN_TITLE_HISTORY_DASHBOARD = '최근 전송 내역'

export const KR_ADMIN_TRANSACTION_NAME_TABLE = '거래 이름'
export const KR_ADMIN_TRANSACTION_DATE_TABLE = '상세 날짜'
export const KR_ADMIN_TRANSACTION_STATUS_TABLE = '상태'
export const KR_ADMIN_TRANSACTION_AMOUNT_TABLE = 'Amount'

// ======= SIDEBAR =======
export const KR_ADMIN_GENERAL_SIDEBAR = '일반'
export const KR_ADMIN_DASHBOARD_SIDEBAR = '대시보드'
export const KR_ADMIN_WALLET_SIDEBAR = '지갑'
export const KR_ADMIN_HISTORY_SIDEBAR = '전송내역'
export const KR_ADMIN_ANALYSIS_SIDEBAR = '정산'
export const KR_ADMIN_USER_SIDEBAR = '사용자'
export const KR_ADMIN_BANNER_SIDEBAR = '배너'
export const KR_ADMIN_BLOG_SIDEBAR = '블로그'

export const KR_ADMIN_SETTING_SIDEBAR = '설정'
export const KR_ADMIN_ACCOUNT_SIDEBAR = '계정'
export const KR_ADMIN_LOGOUT_SIDEBAR = '로그아웃'

// ======= WALLET PAGE =======
export const KR_ADMIN_WALLET_NAME_TABLE = '지갑 목록'
export const KR_ADMIN_WALLET_TYPE_TABLE = '타입'
export const KR_ADMIN_WALLET_NETWORK_TABLE = '네트워크'
export const KR_ADMIN_WALLET_ADDRESS_TABLE = '주소'

// ======= ANALYSIS PAGE =======
export const KR_ADMIN_ANALYSIS_TITLE_SECTION = '거래 내역'
export const KR_ADMIN_ANALYSIS_SETTLEMENTTOTAL = '정산합계'

// ======= USER PAGE =======
export const KR_ADMIN_AGENT_USER = '대리점'
export const KR_ADMIN_GAMESITE_USER = '게임 사이트'
export const KR_ADMIN_USER_USER = '사용자'

export const KR_ADMIN_CREATE_USER = '사용자 신규 생성'
export const KR_ADMIN_UPDATE_USER = '사용자 정보 수정'

export const KR_ADMIN_USER_ID_TABLE = '이름 (ID)'
export const KR_ADMIN_USER_EMAIL_TABLE = '이메일'
export const KR_ADMIN_USER_TELEGRAM_TABLE = '텔레그램'
export const KR_ADMIN_USER_TAG_TABLE = '태그'
export const KR_ADMIN_USER_GAMESITE_TABLE = '게임 사이트'
export const KR_ADMIN_USER_GRADE_TABLE = '등급'

export const KR_ADMIN_USER_PHONE_CREATE = '전화번호 (선택사항)'
export const KR_ADMIN_USER_PHONE_PLA_CREATE = '입력하세요'
export const KR_ADMIN_USER_EMAIL_CREATE = '이메일 (선택사항)'
export const KR_ADMIN_USER_EMAIL_PLA_CREATE = '입력하세요'
export const KR_ADMIN_USER_TELEGRAM_CREATE = '텔레그램 (선택사항)'
export const KR_ADMIN_USER_TELEGRAM_PLA_CREATE = '입력하세요'

export const KR_ADMIN_USER_CODE_CREATE = '보안코드'
export const KR_ADMIN_USER_CODE_PLA_CREATE = '입력하세요'

export const KR_ADMIN_USER_PASSWORD_PLA_CREATE = '입력하세요'
export const KR_ADMIN_USER_ID_PLA_CREATE = '입력하세요'

// ======= BANNER PAGE =======
export const KR_ADMIN_CREATE_BANNER = '배너 생성'

export const KR_ADMIN_BANNER_NAME_TABLE = '이름'
export const KR_ADMIN_BANNER_IMAGE_TABLE = '이미지'
export const KR_ADMIN_BANNER_LINK_TABLE = '링크'
export const KR_ADMIN_BANNER_CONTENT_TABLE = '콘텐츠'

export const KR_ADMIN_UPDATE_BANNER = '배너 편집'

// ======= BLOG PAGE =======
export const KR_ADMIN_CREATE_BLOG = '블로그 생성'

export const KR_ADMIN_BLOG_TITLE_TABLE = '제목'
export const KR_ADMIN_BLOG_DATE_TABLE = '날짜'
export const KR_ADMIN_BLOG_CONTENT_TABLE = '콘텐츠'

export const KR_ADMIN_UPDATE_BLOG = '블로그 편집'

// ======= MY ACCOUNT PAGE =======
export const KR_ADMIN_MYWALLET_ACCOUNT = '내지갑'
export const KR_ADMIN_INFOR_ACCOUNT = '프로필'

export const KR_ADMIN_ACCOUNT_ID_FORM = 'ID'
export const KR_ADMIN_ACCOUNT_EMAIL_FORM = '이메일'
export const KR_ADMIN_ACCOUNT_PASSWORD_FORM = '비밀번호'
export const KR_ADMIN_ACCOUNT_TELEGRAM_FORM = '텔레그램'
export const KR_ADMIN_ACCOUNT_LEVEL_FORM = '레벨'
export const KR_ADMIN_ACCOUNT_SITE_FORM = '사이트'
export const KR_ADMIN_ACCOUNT_URL_FORM = 'Callback URL'
export const KR_ADMIN_ACCOUNT_PHONE_FORM = '핸드폰'
export const KR_ADMIN_ACCOUNT_CODE_FORM = '보안 코드'

export const KR_ADMIN_ACCOUNT_EXCHANGE_FORM = '환율'
export const KR_ADMIN_ACCOUNT_WARNING_FORM = '주의사항'

export const KR_ADMIN_ACCOUNT_UPDATEINFOR_FORM = '프로필 수정'
export const KR_ADMIN_ACCOUNT_CREATE_TOKEN_FORM = '지갑 주소 수정'


// ===== ERROR ===== //
export const ERROR_REQUIRE = '필수 입력 항목입니다.'
export const ERROR_EMPTY_VALUE = 'ID와 비밀번호가 일치하지 않습니다. 다시 시도해 주시기 바랍니다.'
export const ERROR_PASSWORD_CONFIRM = '비밀번호 형식이 올바르지 않습니다.'
