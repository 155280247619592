import React, { useState } from 'react'
import { PopupAdmin } from './user/Popup'
import { ERROR_REQUIRE, KR_ADMIN_CREATE_BANNER } from '../translations/kor'
import ImageForm from './ImageForm'
import LabelForm from './LabelForm'
import InputForm from './InputForm'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import TextareaForm from './TextareaForm'
import ButtonSecondary from './ButtonSecondary'
import ButtonPrimary from './ButtonPrimary'
import { useDispatch } from 'react-redux'
import { createBanners } from '../reduxs/actions/banner.action'
import renderError from './RenderError'

export default function CreateBanner({ handleShow, show }) {
    const dispatch = useDispatch()

    const [namefile, setNameFile] = useState()
    const [isError, setIsError] = useState()

    const handleSelectFile = (e) => {
        formik.setFieldValue('banner', e.target.files[0])
        setNameFile(e.target.files[0].name)
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            link: '',
            content: '',
            banner: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(ERROR_REQUIRE),
            link: Yup.string().required(ERROR_REQUIRE),
            content: Yup.string().required(ERROR_REQUIRE),
            banner: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('link', values.link)
            formData.append('content', values.content)
            formData.append('banner', values.banner)

            const result = await dispatch(createBanners(formData))

            const res = result.payload

            if (res.status === 1) {
                handleShow()
            } else {
                setIsError(res.code)
            }
        }
    })

    return (
        <PopupAdmin handleShow={handleShow} show={show} className='flex flex-col items-center gap-5'>
            <h3 className='lg:text-text30-40 text-text20-40 font-bold'>{KR_ADMIN_CREATE_BANNER}</h3>

            <form className='w-full flex flex-col gap-4 text-white' onSubmit={formik.handleSubmit}>
                {
                    isError
                    &&
                    <p className='text-text14-20 text-red w-full text-center'>{renderError(isError)}</p>
                }
                <ImageForm
                    name='banner'
                    namefile={namefile}
                    onChange={handleSelectFile}
                    error={(formik.touched.banner && formik.errors.banner) && formik.errors.banner}
                />

                <div>
                    <LabelForm label='이름' htmlFor='name' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='name'
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        placeholder={'이름 입력해주세요'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.name && formik.errors.name) && formik.errors.name}
                    />
                </div>
                <div>
                    <LabelForm label='링크' htmlFor='link' className='!text-text14-20' />
                    <InputForm
                        type='text'
                        name='link'
                        onChange={formik.handleChange}
                        value={formik.values.link}
                        placeholder={'링크 입력해주세요'}
                        className='!py-1.5 !px-3 !text-text14-20 !bg-[#1C2F4E] text-white'
                        error={(formik.touched.link && formik.errors.link) && formik.errors.link}
                    />
                </div>
                <div>
                    <LabelForm label='콘텐츠' htmlFor='content' className='!text-text14-20' />
                    <TextareaForm
                        className='!bg-input resize-none w-full'
                        row={6}
                        name='content'
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        placeholder='콘텐츠 입력해주세요...'
                        error={(formik.touched.banner && formik.errors.banner) && formik.errors.banner}
                    />
                </div>

                <div className='grid grid-cols-2 gap-2'>
                    <ButtonSecondary title='취소' onClick={handleShow} />
                    <ButtonPrimary title='확인' onClick={formik.handleSubmit} type='submit' />
                </div>
            </form>
        </PopupAdmin>
    )
}
