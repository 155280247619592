import React from 'react'
import {
    KR_ADMIN_WALLET_ADDRESS_TABLE,
    KR_ADMIN_WALLET_NAME_TABLE,
    // KR_ADMIN_WALLET_NETWORK_TABLE,
    KR_ADMIN_WALLET_TYPE_TABLE
} from '../../../translations/kor'

export default function ListWallet({ data }) {
    return (
        <section className='p-5 pb-[5px] bg-darkblue rounded-20 overflow-x-auto'>
            <table className='w-full'>
                <thead>
                    <tr className='text-text16-20 font-bold '>
                        <th className='pb-4 text-start min-w-[160px] max-lg:text-text14-20'>{KR_ADMIN_WALLET_NAME_TABLE}</th>
                        <th className='pb-4 text-center min-w-[100px] max-lg:text-text14-20'>{KR_ADMIN_WALLET_TYPE_TABLE}</th>
                        {/* <th className='pb-4 text-center'>{KR_ADMIN_WALLET_NETWORK_TABLE}</th> */}
                        <th className='pb-4 text-center lg:w-1/3 min-w-[310px] max-lg:text-text14-20'>{KR_ADMIN_WALLET_ADDRESS_TABLE}</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map(item =>
                            <RowWalletItem
                                key={item.id}
                                id={item.id}
                                address={item.address}
                                network={item.network}
                                type={item.type}
                                wallet_list={item.wallet_list}
                            />)
                    }
                </tbody>
            </table>
        </section>
    )
}

const RowWalletItem = ({ id, wallet_list, type, network, address }) => {
    return (
        <tr className='border-t border-stroke'>
            <td className='py-4'>{wallet_list}</td>
            <td className='py-4 text-center'>{type}</td>
            {/* <td className='py-4 text-center'>{network}</td> */}
            <td className='py-4 text-center lg:w-1/3'>
                <p className='min-w-[310px] break-all line-clamp-1'>{address}</p>
            </td>
        </tr>
    )
}
