import React, { useState } from 'react'
import {
    ERROR_REQUIRE,
    KR_ADMIN_TITLE_LOGIN,
    KR_USER_ACCOUNT_INPUT_LOGIN,
    KR_USER_ACCOUNT_LOGIN,
    KR_USER_PASSWORD_INPUT_LOGIN,
    KR_USER_PASSWORD_LOGIN,
    KR_USER_SUBMIT_LOGIN
} from '../../translations/kor'
import LabelForm from '../../components/LabelForm'
import InputForm from '../../components/InputForm'
import ButtonPrimary from '../../components/ButtonPrimary'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { setLocalStorage } from '../../utils/localStorage'
import { login } from '../../reduxs/actions/auth.action'
import RenderError from '../../components/RenderError'
import { useNavigate } from 'react-router-dom'

export default function LoginAdmin() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [isError, setIsError] = useState(false)

    const formik = useFormik({
        initialValues: {
            id: '',
            password: '',
        },
        validationSchema: Yup.object({
            id: Yup.string().required(ERROR_REQUIRE),
            password: Yup.string().required(ERROR_REQUIRE),
        }),
        onSubmit: async values => {
            const result = await dispatch(login(values))

            const response = result.payload

            if (response.status === 1) {
                setLocalStorage('token', response.data.access_token)
                setLocalStorage('refreshToken', response.data.refresh_token)
                setLocalStorage('role', response.data.role)

                return response.data.namesite ? navigate(`/admin/${response.data.namesite}`) : navigate(`/admin`)
            } else {
                setIsError(response.code)
            }
        }
    })

    return (
        <main className='flex justify-center items-center h-screen py-5 px-5 bg-bg-login bg-no-repeat bg-cover w-full' >
            <form onSubmit={formik.handleSubmit} className='bg-lightGray p-15 max-lg:py-10 max-lg:px-5 max-w-[600px] w-full rounded-24 text-white flex flex-col items-center gap-[30px] backdrop-blur-xl'>
                <h2 className='lg:text-text30-40 font-bold'>{KR_ADMIN_TITLE_LOGIN}</h2>

                <div className='w-full grid gap-4'>
                    <div className='w-full'>
                        <LabelForm label={KR_USER_ACCOUNT_LOGIN} htmlFor='id' />
                        <InputForm
                            type='text'
                            name='id'
                            value={formik.values.id}
                            onChange={(e) => {
                                setIsError(false)
                                formik.handleChange(e)
                            }}
                            placeholder={KR_USER_ACCOUNT_INPUT_LOGIN}
                            error={(formik.touched.id && formik.errors.id) && formik.errors.id}
                        />
                    </div>

                    <div className='w-full'>
                        <LabelForm label={KR_USER_PASSWORD_LOGIN} />
                        <InputForm
                            type='password'
                            name='password'
                            value={formik.values.password}
                            onChange={(e) => {
                                setIsError(false)
                                formik.handleChange(e)
                            }}
                            placeholder={KR_USER_PASSWORD_INPUT_LOGIN}
                            error={(formik.touched.password && formik.errors.password) && formik.errors.password}
                        />
                    </div>

                    {
                        isError
                        &&
                        <p className='text-red text-text10-12'>{RenderError(isError)}</p>
                    }
                </div>

                <ButtonPrimary type='submit' title={KR_USER_SUBMIT_LOGIN} className='w-[200px]' onClick={formik.handleSubmit} />
            </form>
        </main>
    )
}
