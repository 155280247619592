import { useFormik } from 'formik'
import React from 'react'
import { ERROR_REQUIRE, KR_ADMIN_ACCOUNT_WARNING_FORM } from '../../../translations/kor'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useDispatch } from 'react-redux'
import { updateWaringAdmin } from '../../../reduxs/actions/auth.action'
import TextareaForm from '../../../components/TextareaForm'
import * as Yup from 'yup'

export default function CreateWarning({ data }) {
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            warning_content: data
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            warning_content: Yup.string().required(ERROR_REQUIRE)
        }),
        onSubmit: async values => {
            const result = await dispatch(updateWaringAdmin(values))

            const res = result.payload

            if (res.status === 1) {
                formik.resetForm()
                formik.setFieldValue('warning_content', formik.values.warning_content)
                alert('Update warning success !!!')
            }
        }
    })

    return (
        <form className='flex flex-col gap-4 flex-grow w-full' onSubmit={formik.handleSubmit}>
            <h3 className='text-text16-20 font-bold'>{KR_ADMIN_ACCOUNT_WARNING_FORM}</h3>

            <div className='bg-darkblue p-5 rounded-20 flex flex-col gap-5 gap-x-5 gap-y-4'>
                <TextareaForm
                    className='resize-none !bg-input py-2 px-4 textarea-sizing min-h-[100px] !text-base'
                    name='warning_content'
                    placeholder='Enter content'
                    row={5}
                    value={formik.values.warning_content}
                    onChange={formik.handleChange}
                    error={(formik.touched.warning_content && formik.errors.warning_content) && formik.errors.warning_content}
                />

                <div className='flex justify-end gap-2.5'>
                    {/* <ButtonPrimary title='Edit' /> */}
                    <ButtonPrimary title='저장' onClick={formik.handleSubmit} type='submit' />
                </div>
            </div>
        </form>
    )
}
