import React, { useEffect } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { TotalPointMonth } from '../../sections/admin/Dashboard/TotalPoint'
import { KR_ADMIN_ANALYSIS_SIDEBAR } from '../../translations/kor'
import AnalysisListData from '../../sections/admin/Analysis/AnalysisListData'
import { useDispatch, useSelector } from 'react-redux'
import { getAnalysisList } from '../../reduxs/actions/analysis.action'
import Loading from '../../components/Loading'

export default function AnalysisAdmin() {
    const dispatch = useDispatch()

    const { dataAnalysis, loadingGetAnalysis, filterAnalysis, analysisTotal, totalListdata } = useSelector(state => state.analysis)

    useEffect(() => {
        dispatch(getAnalysisList(1))
    }, [])

    if (loadingGetAnalysis || !dataAnalysis) {
        return <Loading />
    }

    return (
        <>
            <TitlePage title={KR_ADMIN_ANALYSIS_SIDEBAR} />

            <TotalPointMonth data={analysisTotal} />

            <AnalysisListData dataAnalysis={dataAnalysis} filter={filterAnalysis} analysisTotal={totalListdata} />
        </>
    )
}

