import { createBrowserRouter } from "react-router-dom";

import UserPage from "../pages/users/home/UserPage";
import DepositPage from "../pages/users/home/DepositPage";
import DetailDeposit from "../pages/users/home/DetailDeposit";
import Withdraw from "../pages/users/home/Withdraw";
import PostPage from "../pages/users/home/PostPage";
import PostDetail from "../pages/users/home/PostDetail";
import MyWallet from "../pages/users/home/MyWallet";
import LoginAdmin from "../pages/admin/LoginAdmin";
import AdminPage from "../pages/admin/AdminPage";
import WalletPage from "../pages/admin/WalletPage";
import HistoryPage from "../pages/admin/HistoryPage";
import UserAdminPage from "../pages/admin/UserAdminPage";
import BannersPage from "../pages/admin/BannersPage";
import BlogPage from "../pages/admin/BlogPage";
import MyAccount from "../pages/admin/MyAccount";
import ProtectRouterUser from "../components/ProtectRouterUser";
import RedeirectIfAuth from "../components/RedeirectIfAuth";
import ConfirmAccount from "../pages/users/auth/ConfirmAccount";
import PinCode from "../pages/users/auth/PinCode";
import Auth from "../pages/users/auth/Auth";
import ProtectRouterAdmin from "../components/ProtectRouterAdmin";
import NotFoundPage from "../pages/NotFoundPage";
import TransactionPage from "../pages/users/home/TransactionPage";
import AnalysisAdmin from "../pages/admin/AnalysisAdmin";

const routers = createBrowserRouter([
    {
        path: '/auth/:gamesite',
        element: <Auth />,
        children: [
            {
                path: 'confirm/:account',
                element:
                    <RedeirectIfAuth>
                        <ConfirmAccount />
                    </RedeirectIfAuth>
            },
            {
                path: 'pin-code/:account',
                element:
                    <RedeirectIfAuth>
                        <PinCode />
                    </RedeirectIfAuth>
            },
        ]
    },
    {
        path: '/user/:game',
        element: <UserPage />,
        children: [
            {
                path: 'deposit',
                element:
                    <ProtectRouterUser>
                        <DepositPage />
                    </ProtectRouterUser>
            },
            {
                path: 'deposit/:id',
                element:
                    <ProtectRouterUser>
                        <DetailDeposit />
                    </ProtectRouterUser>
            },
            {
                path: 'withdraw',
                element:
                    <ProtectRouterUser>
                        <Withdraw />
                    </ProtectRouterUser>
            },
            {
                path: 'posts',
                element:
                    <ProtectRouterUser>
                        <PostPage />
                    </ProtectRouterUser>
            },
            {
                path: 'posts/:id',
                element:
                    <ProtectRouterUser>
                        <PostDetail />
                    </ProtectRouterUser>
            },
            {
                path: 'my-wallet',
                element:
                    <ProtectRouterUser>
                        <MyWallet />
                    </ProtectRouterUser>
            },
            {
                path: 'transaction',
                element:
                    <ProtectRouterUser>
                        <TransactionPage />
                    </ProtectRouterUser>
            },
        ]
    },
    {
        path: '/admin/:game?',
        element: <AdminPage />,
        children: [
            {
                path: 'wallets',
                element:
                    <ProtectRouterAdmin>
                        <WalletPage />
                    </ProtectRouterAdmin>
            },
            {
                path: 'historys',
                element:
                    <ProtectRouterAdmin>
                        <HistoryPage />
                    </ProtectRouterAdmin>
            },
            {
                path: 'users',
                element:
                    <ProtectRouterAdmin>
                        <UserAdminPage />
                    </ProtectRouterAdmin>
            },
            {
                path: 'analysis',
                element:
                    <ProtectRouterAdmin>
                        <AnalysisAdmin />
                    </ProtectRouterAdmin>
            },
            {
                path: 'banners',
                element:
                    <ProtectRouterAdmin>
                        <BannersPage />
                    </ProtectRouterAdmin>
            },
            {
                path: 'blogs',
                element:
                    <ProtectRouterAdmin>
                        <BlogPage />
                    </ProtectRouterAdmin>
            },
            {
                path: 'my-account',
                element:
                    <ProtectRouterAdmin>
                        <MyAccount />
                    </ProtectRouterAdmin>
            },
        ]
    },
    {
        path: '/admin/auth',
        element:
            <RedeirectIfAuth>
                <LoginAdmin />
            </RedeirectIfAuth>
        ,
    },
    {
        path: '*',
        element: <NotFoundPage />
    },
])

export default routers