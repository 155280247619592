import React from 'react'
// import { BsPencilSquare } from 'react-icons/bs'

export default function InputUpdateForm({ label, defaluValue, lock }) {
    return (
        <div>
            <label className='block mb-2.5 text-text14-20'>{label}</label>

            <div className={`flex justify-between items-center lg:py-2 lg:px-6 py-1.5 px-3 ${lock ? 'bg-input-lock text-deactivate' : 'bg-input'} rounded-12`}>
                <p className='lg:text-text16-26 text-text16-20'>{defaluValue}</p>

                {
                    // lock ? '' : <button><BsPencilSquare /></button>
                }
            </div>
        </div>
    )
}
