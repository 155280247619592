import React from 'react'
import {
    KR_ADMIN_DEPOSIT_DASHBOARD,
    KR_ADMIN_DEPOSIT_TODAY_DASHBOARD,
    KR_ADMIN_POINT_DASHBOARD,
    KR_ADMIN_WITHDRAW_DASHBOARD,
    KR_ADMIN_WITHDRAW_TODAY_DASHBOARD
} from '../../../translations/kor'

export default function TotalPoint({ data, month = false }) {
    return (
        <section className='grid lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2.5'>
            <div className='bg-bg-deposit bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>{KR_ADMIN_DEPOSIT_DASHBOARD}</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.[month ? 'total_month_deposit' : 'today_deposit']} {!month && KR_ADMIN_POINT_DASHBOARD}</p>
            </div>

            <div className='bg-bg-withdraw bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>{KR_ADMIN_WITHDRAW_DASHBOARD}</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.deposit} {!month && KR_ADMIN_POINT_DASHBOARD}</p>
            </div>

            <div className='bg-bg-depositToday bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>{KR_ADMIN_DEPOSIT_TODAY_DASHBOARD}</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.[month ? 'total_month_withdrawal' : 'today_withdrawal']} {!month && KR_ADMIN_POINT_DASHBOARD}</p>
            </div>

            <div className='bg-bg-withdrawToday bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>{KR_ADMIN_WITHDRAW_TODAY_DASHBOARD}</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.withdrawal} {!month && KR_ADMIN_POINT_DASHBOARD}</p>
            </div>
        </section>
    )
}

export const TotalPointMonth = ({ data }) => {
    return (
        <section className='grid lg:grid-cols-4 grid-cols-2 lg:gap-4 gap-2.5'>
            <div className='bg-bg-deposit bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>이번달 입금</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.total_month_deposit}</p>
            </div>

            <div className='bg-bg-withdraw bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>이번달 출금</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.total_month_withdrawal}</p>
            </div>

            <div className='bg-bg-depositToday bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>전체 입금</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.deposit}</p>
            </div>

            <div className='bg-bg-withdrawToday bg-no-repeat bg-cover bg-center p-5 w-full rounded-20 flex flex-col lg:gap-4 gap-2.5'>
                <p className='lg:text-text16-26 text-text14-20'>전체 출금</p>

                <p className='lg:text-text40-50 text-text16-20 font-bold'>{data?.withdrawal}</p>
            </div>
        </section>
    )
}
