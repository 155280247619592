import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "../../utils/api";

// get all notify
export const getAllNotifycation = createAsyncThunk(
    'notify/getall',
    async () => {
        const response = await get(`/admin/notifications`)

        return response
    }
)
