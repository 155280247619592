import React, { useEffect } from 'react'
import TitlePage from '../../components/admin/TitlePage'
import { KR_ADMIN_WALLET_SIDEBAR } from '../../translations/kor'
import CompanyPointItem from '../../components/admin/CompanyPointItem'
import ListWallet from '../../sections/admin/Wallet/ListWallet'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAllWallet } from '../../reduxs/actions/wallet.action'
import Loading from '../../components/Loading'

export default function WalletPage() {
    const dispatch = useDispatch()

    const { game } = useParams()

    const { loadingGetWallet, dataWallet } = useSelector(state => state.wallet)

    useEffect(() => {
        dispatch(getAllWallet(game || ''))
    }, [game, dispatch])

    if (loadingGetWallet || !dataWallet) {
        return <Loading />
    }

    const { total_wallet, data_wallet } = dataWallet

    return (
        <>
            <TitlePage title={KR_ADMIN_WALLET_SIDEBAR} />

            <section className='grid lg:grid-cols-4 grid-cols-2 gap-4'>
                {
                    total_wallet.map(item => <CompanyPointItem key={item.name} name={item.name} count={item.count} logo={item.logoToken} />)
                }
            </section>

            <ListWallet data={data_wallet} />
        </>
    )
}
