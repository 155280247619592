import { createSlice } from "@reduxjs/toolkit";
import { getAllNotifycation } from "../actions/notifycation.action";

const notifySlice = createSlice({
    name: 'notify',
    initialState: {
        loadingGettNotifycation: false,
        dataNotifycation: [],
        isRead: false
    },
    reducers: {
        updateNotifycation: (state, action) => {
            const res = action.payload

            state.dataNotifycation = [
                res.new_notification,
                ...state.dataNotifycation,
            ]
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllNotifycation.pending, (state, action) => {
            state.loadingGettNotifycation = true
        })
        builder.addCase(getAllNotifycation.fulfilled, (state, action) => {
            const res = action.payload

            if (res.status) {
                state.dataNotifycation = res.data.list_notification
                state.isRead = res.data.is_real
            }

            state.loadingGettNotifycation = false
        })
    }
})

export const { updateNotifycation } = notifySlice.actions
export default notifySlice.reducer
