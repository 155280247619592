import axios from "axios"
import { clearLocalStorage, getLocalStorage, setLocalStorage } from "./localStorage"

export const URL_SERVER = process.env.REACT_APP_SERVER_API || 'http://192.168.1.12:8080/api/v1/'
export const URL_SERVER_IMAGE = process.env.REACT_APP_SERVER_IMAGE || 'http://192.168.1.12:8080/storage/img/'

const instaince = axios.create({
    baseURL: URL_SERVER,
    // withCredentials: true, 
})

instaince.interceptors.request.use(config => {
    const token = getLocalStorage('token')

    config.headers["Authorization"] = `${token}`

    return config
}, (err) => {
    return Promise.reject(err)
})

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

instaince.interceptors.response.use(res => {
    return res
}, async err => {
    const originalRequest = err.config;
    const statusError = err.response.status

    if (statusError === 403) {  // handle when status err 403
        clearLocalStorage()
        window.location.href = '/auth/diko'
    }

    if (statusError === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            })
                .then(token => {
                    originalRequest.headers['Authorization'] = token;
                    return instaince(originalRequest);
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = getLocalStorage('refreshToken');

        return new Promise((resolve, reject) => {
            post('/auth/refresh', { old_token: refreshToken }) // refresh token
                .then((data) => {
                    if (data.status) {
                        setLocalStorage('token', data.accessToken);
                        setLocalStorage('refreshToken', data.refreshToken);
                        instaince.defaults.headers['Authorization'] = data.accessToken;
                        originalRequest.headers['Authorization'] = data.accessToken;
                        processQueue(null, data.accessToken);
                        resolve(instaince(originalRequest));
                    } else {
                        clearLocalStorage();
                        window.location.href = '/auth/diko';
                        reject(err);
                    }
                })
                .catch((error) => {
                    processQueue(error, null);
                    clearLocalStorage();
                    window.location.href = '/auth/diko';
                    reject(error);
                })
                .finally(() => {
                    isRefreshing = false;
                });
        });
    }

    return Promise.reject(err);
})

export const get = async (url) => {
    try {
        const res = await instaince.get(url)

        return res.data
    } catch (err) {
        return console.log(err.message)
    }
}

export const post = async (url, body) => {
    try {
        const res = await instaince.post(url, body)

        return res.data
    } catch (err) {
        return console.log(err.message)
    }
}