import React, { useState } from 'react'
import { KR_ADMIN_ACCOUNT_EXCHANGE_FORM } from '../../../translations/kor'
import Loading from '../../../components/Loading'
import { URL_SERVER_IMAGE } from '../../../utils/api'
import { BsPencilSquare } from 'react-icons/bs'
import ChangeRatePopup from '../../../components/Changerate'

export default function ChangeRate({ data }) {
    const [isShowChangeRate, setIsShowChangeRate] = useState(false)

    // is show change rate
    const handleShowChangeRate = () => {
        setIsShowChangeRate(!isShowChangeRate)
    }

    if (!data) {
        return <Loading />
    }

    const { rate, gamepoint } = data

    return (
        <section className='flex flex-col gap-4 w-full'>
            <div className='flex items-center gap-2'>
                <h3 className='text-text16-20 font-bold'>{KR_ADMIN_ACCOUNT_EXCHANGE_FORM}</h3>

                <button type='button' onClick={handleShowChangeRate}>
                    <BsPencilSquare />
                </button>
            </div>

            <div className='bg-darkblue p-5 rounded-20 flex flex-col gap-5 gap-x-5 gap-y-4 flex-grow'>
                <div className='flex justify-between items-center'>
                    <div className='flex items-center gap-2'>
                        <img src={`${URL_SERVER_IMAGE}${gamepoint.gamepoint_logo}`} alt='' className='w-[26px] aspect-square rounded-full object-cover' />

                        <span className='max-lg:text-text14-20'>{gamepoint.gamepoint_name}</span>
                    </div>

                    <p className='py-1 px-4 rounded-8 w-[120px] bg-input text-center'>{gamepoint.gamepoint_rate}</p>
                </div>

                {
                    rate?.map(item =>
                        <ChangeRateItem
                            key={item.id}
                            logo={item.logoToken}
                            name={item.name}
                            id={item.id}
                            value={item.value_token}
                            valueToken={item.value_token}
                        />)
                }
            </div>

            <ChangeRatePopup handleShow={handleShowChangeRate} show={isShowChangeRate} data={data} />
        </section>
    )
}

export const ChangeRateItem = ({ name, logo, value }) => {
    return (
        <div className='flex justify-between items-center'>
            <div className='flex items-center gap-2'>
                <img src={`${URL_SERVER_IMAGE}${logo}`} alt='' className='w-[26px] aspect-square object-cover' />

                <span className='max-lg:text-text14-20'>{name}</span>
            </div>

            <p className='py-1 px-4 rounded-8 w-[120px] bg-input text-center max-lg:text-text14-20'>{value}</p>
        </div>
    )
}
