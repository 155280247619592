import React, { useEffect, useRef, useState } from 'react'
import {
    DELETE,
    FIX,
    KR_ADMIN_USER_EMAIL_TABLE,
    KR_ADMIN_USER_GAMESITE_TABLE,
    KR_ADMIN_USER_GRADE_TABLE,
    KR_ADMIN_USER_ID_TABLE,
    KR_ADMIN_USER_TAG_TABLE,
    KR_ADMIN_USER_TELEGRAM_TABLE
} from '../../../translations/kor'
import { IoEllipsisVertical } from 'react-icons/io5'
import { getLocalStorage } from '../../../utils/localStorage'
import PopupDelete from '../../../components/admin/PopupDelete'
import { useDispatch } from 'react-redux'
import { deleteUser } from '../../../reduxs/actions/user.action'
import renderError from '../../../components/RenderError'
import UpdateUser from '../../../components/UpdateUser'

export default function ListUserAdmin({ data }) {
    return (
        <section className='p-5 pb-[5px] bg-darkblue rounded-20 overflow-x-auto'>
            <table className='w-full'>
                <thead>
                    <tr className='text-text16-20 font-bold '>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[60px] text-start w-5'>#</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[160px] px-2.5 text-center w-2/12'>{KR_ADMIN_USER_ID_TABLE}</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[200px] px-2.5 text-center'>{KR_ADMIN_USER_EMAIL_TABLE}</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[100px] px-2.5 text-center w-2/12'>{KR_ADMIN_USER_TELEGRAM_TABLE}</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[100px] px-2.5 text-center w-2/12'>{KR_ADMIN_USER_TAG_TABLE}</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[100px] px-2.5 text-center w-2/12'>{KR_ADMIN_USER_GAMESITE_TABLE}</th>
                        <th className='pb-4 max-lg:text-text14-20 min-w-[150px] px-2.5 text-center w-2/12'>{KR_ADMIN_USER_GRADE_TABLE}</th>
                        <th className='text-end w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((item, index) =>
                            <RowUserItem
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                email={item.email}
                                role_name={item.role_name}
                                site_name={item.site_name}
                                telegram_id={item.telegram_id}
                                tag={item.tag}
                                last={(index + 1) === data.length}
                            />)
                    }
                </tbody>
            </table>

        </section>
    )
}

const RowUserItem = ({ id, name, email, telegram_id, tag, site_name, role_name, last }) => {
    const role = getLocalStorage('role')
    const dispatch = useDispatch()

    const [isShowUpdateUser, setIsShowUpdateUser] = useState(false)
    const [isShowOption, setIsShowOption] = useState(false)
    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isError, setIsError] = useState(false)

    // show option
    const handleShowOption = () => {
        setIsShowOption(!isShowOption)
    }

    // show update user
    const handleShowUpdateUser = () => {
        setIsShowUpdateUser(!isShowUpdateUser)
    }

    // show popup delete
    const handleShowDelete = () => {
        setIsShowDelete(!isShowDelete)
    }

    // handle delete user
    const handleDeleteUser = async (idUser) => {
        const dataSubmit = {
            user_id: id
        }

        const result = await dispatch(deleteUser(dataSubmit))

        const res = result.payload

        if (res.status === 1) {
            handleShowDelete()
        } else {
            setIsError(renderError(res.code))
        }
    }

    const contentRef = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            if (contentRef.current && !contentRef.current.contains(event.target)) {
                handleShowOption()
            }
        }

        if (isShowOption) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [contentRef, isShowOption]);

    return (
        <>
            <PopupDelete
                isShow={isShowDelete}
                handleCancel={handleShowDelete}
                content={<>
                    <p>사용자를 삭제 하시겠습니까?</p>
                    <p> 삭제된 계정은 복구할 수 없습니다.</p>
                </>}
                error={isError}
                handleConfirm={() => handleDeleteUser(id)}
            />

            {
                isShowUpdateUser
                &&
                <UpdateUser handleShow={handleShowUpdateUser} show={isShowUpdateUser} id={id} />
            }

            <tr className='border-t border-stroke'>
                <td className='py-4 w-5'>{id}</td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'>{name}</td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'><p className='line-clamp-1 break-all'>{email}</p></td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'>{telegram_id}</td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'>{tag}</td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'>{site_name}</td>
                <td className='py-4 px-2.5 text-center max-lg:text-text14-20'>{role_name}</td>
                <td className='text-end w-5 max-lg:text-text14-20'>
                    <div className='relative'>
                        <button type='button' onClick={handleShowOption}>
                            <IoEllipsisVertical />
                        </button>

                        {
                            isShowOption
                            &&
                            <div className={`absolute ${last ? 'bottom-full' : 'top-full'} right-0 w-[188px] bg-white rounded-12 text-black`} ref={contentRef}>
                                <ul className='grid text-center'>
                                    <li>
                                        <button className='px-4 py-2.5 hover:bg-lightBlue w-full first:rounded-t-12 duration-200' onClick={handleShowUpdateUser}>
                                            {FIX}
                                        </button>
                                    </li>
                                    {
                                        role === 'total-admin'
                                        &&
                                        <li>
                                            <button className='px-4 py-2.5 hover:bg-lightBlue w-full last:rounded-b-12 duration-200' onClick={handleShowDelete}>
                                                {DELETE}
                                            </button>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </td>
            </tr>
        </>
    )
}
